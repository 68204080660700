import React from 'react';
import Style from './Contact.module.scss';
import Terminal from "./Terminal";
import {Box} from "@mui/material";
import {info} from "../../info/Info";

export default function Contact() {
    const firstName = info.firstName.toLowerCase()

    function skillsText() {
        return <>
            <h3 align="center"><span style={{color: info.baseColor}}>{"Contact Me"}</span></h3>
            &nbsp;
            <table cellpadding="8px" border="0" >
            <tr>
            <p style={{color: info.baseColor}}> </p>
            <td style={{color: info.baseColor}}>Name</td>
            <td><div class="contact-form" align="left"><input placeholder="Name" name="GONDERENIN_ADI_SOYADI" size="40" type="text"></input></div></td>
            </tr>
            &nbsp;
            <tr>
            <p style={{color: info.baseColor}}> </p>
            <td style={{color: info.baseColor}}>Mail</td>
            <td><div class="contact-form" align="left"><input placeholder="Mail" name="GONDERENIN_ADI_SOYADI" size="40" type="text"></input></div></td>
            </tr>
            &nbsp;
            <tr>
            <p style={{color: info.baseColor}}> </p>
            <td style={{color: info.baseColor}}>Content &nbsp;</td>
            <td><div align="left"><textarea placeholder="Content" name="" cols="36" rows="8" type="text"></textarea></div></td>
            </tr>
            &nbsp;
            <tr>
                <td></td></tr>
                <input value="Gönder" name="send" size="15" type="submit" id="send" ></input>
            </table>
            
        </>;
    }

    function miscText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd
                hobbies/interests</p>
            <p><span style={{color: info.baseColor}}>hobbies/interests <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <ul>
                {info.hobbies.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>{hobby.label}</li>
                ))}
            </ul>
        </>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={skillsText()}/>
            <Terminal text={miscText()}/>
        </Box>
    )
}